import { AxiosError } from "axios";
import { toast } from "react-toastify";
import isString from "lodash/isString";

export const getErrorMessage = (e: AxiosError<{ error: string }> | any) => {
  if (isString(e?.response?.data?.error?.detail))
    return e?.response?.data?.error?.detail;
  if (isString(e?.response?.data?.message)) return e?.response?.data?.message;
  if (isString(e?.response?.data?.error)) return e?.response?.data?.error;
  if (isString(e.message)) return e.message;
};
export const toastRequestError = (e: AxiosError<{ error: string }> | any) => {
  toast.error(getErrorMessage(e));
};
