import {
  RoomConnectionType,
  RoomDisplayType,
  RoomEncryptType,
} from "../services";

export const ROOM_DISPLAY_TYPE: {
  value: RoomDisplayType;
  title: string;
  description: string;
}[] = [
  { value: "public", title: "Public", description: "Any one can join" },
  {
    value: "normal",
    title: "Normal",
    description:
      "Only people with invitation or acceptance from owner could join",
  },
  {
    value: "private",
    title: "Private",
    description: "Only people with invitation could join",
  },
];

export const ROOM_CONNECTION_TYPE: {
  value: RoomConnectionType;
  title: string;
  description: string;
}[] = [
  {
    value: "server",
    title: "Server",
    description: "Message distributes through servers",
  },
  {
    value: "p2p",
    title: "P2P",
    description: "Message distributes through peer to peer technology",
  },
];

export const ROOM_ENCRYPT_TYPE: {
  value: RoomEncryptType;
  title: string;
  description: string;
}[] = [
  { value: "none", title: "None", description: "No encryption" },
  {
    value: "aes_256_cbc",
    title: "AES256CBC",
    description: "Encryption using AES256CBC",
  },
  {
    value: "key_exchange",
    title: "Key Exchange",
    description: "Encryption using enhanced key exchange",
  },
];

export const STATUS = {
  JOINED: "joined",
  REQUESTED: "requested",
  REJECTED: "rejected",
  REMOVED: "removed",
  BANNED: "banned",
  LEFT: "left",
};

export const DISPLAY_TYPE = {
  PUBLIC: "public",
  NORMAL: "normal",
  PRIVATE: "private",
};

export const MAX_WIDTH_SIDEBAR = 400;

export const SIDEBAR_TYPE = {
  CONTACT: "CONTACT",
  STARRED: "STARRED",
  SHARED: "SHARED",
};

export const PATH_INDEX = [0, 2, 3];
export const MENU_PATH_INDEX = [0, 1, 2];

export const TYPE = {
  MSG: "msg",
  DIVIDER: "divider",
};
export const SUBTYPE = {
  TEXT: "text",
  IMG: "img",
  DOC: "doc",
  LINK: "link",
  REPLY: "reply",
};
export const MESSAGE_TYPE = {
  FILE: 'file',
  HTML: 'html',
  TEXT: 'text'
}
