import { CircularProgress, Stack, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { getErrorMessage } from "../utils/error";

function LoadingWrapper({
  isLoading,
  children,
  error,
}: PropsWithChildren<{ isLoading: boolean; error?: string }>) {
  const loading = isLoading || !!error;
  return loading ? (
    <Stack
      sx={{
        minWidth: "100%",
        minHeight: "100%",
      }}
      justifyContent={"center"}
      alignItems={"center"}
    >
      {error ? (
        <Typography>{getErrorMessage(error)}</Typography>
      ) : (
        <CircularProgress />
      )}
    </Stack>
  ) : (
    <>{children}</>
  );
}

export default LoadingWrapper;
