import { initializeApp } from "firebase/app";

import { getMessaging } from "firebase/messaging";
import { firebaseConfig } from "../config";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
