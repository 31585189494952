import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { setAllChatUnreadCount } from "../redux/slices/chat";
import { useAppDispatch } from "../redux/store";
import { ChatWSAxios, Room, UserRoom } from "../services";
import { mergeArrays } from "../utils/array";
import { getAllLocalChatUnreadCount } from "../utils/local-storage";

export const ChatContext = createContext<{
  rooms: Room[];
  setRooms: Dispatch<SetStateAction<Room[]>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  total: number;
  setTotal: Dispatch<SetStateAction<number>>;
  getRoomList: (search: string) => void;
  selectedRoom: Room | undefined;
  setSelectedRoom: Dispatch<SetStateAction<Room | undefined>>;
  selectedRoomMembers: Record<string, UserRoom>;
  selectedRoomCreatorUser: UserRoom | undefined;
  inviteMembers: (roomId: string, members: UserRoom[]) => void;
  removeMembers: (room: Room, member: UserRoom[]) => void;
  updateMember: (roomId: string, memberId: string, nickname: string) => void;
}>({
  getRoomList: () => {},
  isLoading: true,
  setIsLoading: () => {},
  rooms: [],
  setRooms: () => {},
  setTotal: () => {},
  total: 0,
  selectedRoom: undefined,
  setSelectedRoom: () => {},
  selectedRoomMembers: {},
  selectedRoomCreatorUser: undefined,
  inviteMembers: () => {},
  removeMembers: () => {},
  updateMember: () => {},
});

export const ChatContextProvider = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  const [rooms, setRooms] = useState<Room[]>([]);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoom, setSelectedRoom] = useState<Room | undefined>(undefined);

  const searchRef = useRef("");

  const [total, setTotal] = useState(0);

  const getRoomList = useCallback(
    async (search: string) => {
      try {
        setIsLoading(true);
        const result = await ChatWSAxios.getReq<{
          rooms: UserRoom[];
          total: number;
        }>("/api/rooms/get-list", {
          params: {
            search,
            ...(!search ? { joined: true } : {}),
            limit: 10,
            offset: searchRef.current !== search ? 0 : rooms?.length ?? 0,
          },
        });

        setRooms(
          (searchRef.current === search
            ? mergeArrays("id", rooms ?? [], result.rooms)
            : result.rooms
          ).map((room) => ({
            ...room,
            latestMessage: room.latestMessage
              ? JSON.parse(room.latestMessage)
              : null,
          }))
        );
        setTotal(result.total);
        searchRef.current = search;
      } catch (error) {
        toast.error("Error while fetching rooms");
      } finally {
        setIsLoading(false);
      }
    },
    [selectedRoom]
  );

  const selectedRoomMembers = useMemo(
    () =>
      (selectedRoom?.users ?? []).reduce(
        (pre, curr) => ({ ...pre, [curr.userId]: curr }),
        {}
      ),
    [selectedRoom]
  );

  const selectedRoomCreatorUser = useMemo(
    () =>
      selectedRoom
        ? (selectedRoom.users ?? []).find((item) => item.isCreator)
        : undefined,
    [selectedRoom]
  );

  const inviteMembers = async (roomId: string, members: UserRoom[]) => {
    const existingMembers = selectedRoom?.users || [];
    const newMembers = members.filter(
      (member) =>
        !existingMembers.some(
          (existingMember) => existingMember.id === member.id
        )
    );

    setRooms((prevRooms) =>
      prevRooms.map((room) => {
        if (room.id === roomId) {
          const updatedRoom = {
            ...room,
            users: [...existingMembers, ...newMembers],
          };
          setSelectedRoom({
            ...updatedRoom,
            users: updatedRoom.users.map((item) => ({
              ...item,
              User: {
                id: item?.User?.id,
                username: item?.User?.username,
                email: item?.User?.email,
                avatar: item?.User?.avatar,
              },
            })),
          });

          return updatedRoom;
        }
        return room;
      })
    );
  };

  const removeMembers = (room: Room, members: UserRoom[]) => {
    const updatedMembers = [...(room.users ?? [])].filter(
      (existingMember) =>
        !members.some(
          (memberToRemove) => memberToRemove.userId === existingMember.User.id
        )
    );
    setRooms((prevRooms) => {
      return prevRooms.map((prevRoom) => {
        if (room.id === prevRoom.id) {
          setSelectedRoom({ ...room, users: updatedMembers });
          return {
            ...room,
            users: updatedMembers,
          };
        }
        return prevRoom;
      });
    });
  };

  const updateMember = (
    roomId: string,
    memberId: string,
    nickname: string
  ) => {
    setRooms((rooms) =>
      rooms.map((item) => {
        if (item.id === roomId) {
          const updateNickname = item.users.map((user) =>
            user.userId === memberId ? { ...user, nickname: nickname } : user
          );
          const updatedRoom = { ...item, users: updateNickname };
          setSelectedRoom(updatedRoom);
          return updatedRoom;
        }
        return item;
      })
    );
  };

  useEffect(() => {
    dispatch(setAllChatUnreadCount(getAllLocalChatUnreadCount()));
    return () => {
      setRooms([]);
      setIsLoading(true);
      setTotal(0);
      searchRef.current = "";
    };
  }, []);

  return (
    <ChatContext.Provider
      value={{
        rooms,
        setRooms,
        isLoading,
        setIsLoading,
        total,
        setTotal,
        getRoomList,
        selectedRoom,
        setSelectedRoom,
        selectedRoomMembers,
        selectedRoomCreatorUser,
        inviteMembers,
        removeMembers,
        updateMember,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => {
  return useContext(ChatContext);
};
