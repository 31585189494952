import Cookies from "js-cookie";

export enum CookiesKeys {
  "auth_data" = "auth_data",
  "login_type" = "login_type",
  "user_register_enable" = "user_register_enable",
  "all_settings" = "all_settings",
}

export const getCookie = (name: CookiesKeys) => {
  const cookieValue = Cookies.get(name);
  if (cookieValue === undefined) return undefined;
  return JSON.parse(cookieValue === "undefined" ? "{}" : cookieValue ?? "{}");
};
export const setCookie = <T>(name: CookiesKeys, value: T, options?: any) => {
  return Cookies.set(name, JSON.stringify(value), options);
};
export const removeCookie = (name: CookiesKeys) => {
  return Cookies.remove(name);
};
