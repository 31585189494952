import { createSlice } from "@reduxjs/toolkit";

// define initial state
const initialState = {
  chatUnreadCount: {},
};

// create chatSlice
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    //Toggle sidebar
    setChatUnreadCount(state, action) {
      state.chatUnreadCount[action.payload.roomId] = action.payload.count;
    },
    setAllChatUnreadCount(state, action) {
      state.chatUnreadCount = action.payload;
    },
  },
});

// export reducer
export default chatSlice.reducer;

// export actions
export const { setChatUnreadCount, setAllChatUnreadCount } = chatSlice.actions;
