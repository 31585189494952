import { Container, Stack } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import Logo from "../../assets/Images/logo.svg";
import { useAuthContext } from "../../contexts/AuthContext";

const MainLayout = () => {
  const { chatUserData } = useAuthContext();
  const isAuthenticated = !!chatUserData;

  if (isAuthenticated) {
    return <Navigate to="/app" />;
  }

  return (
    <Container sx={{ mt: 5 }} maxWidth="sm">
      <Stack spacing={5}>
        <Stack sx={{ width: "100%" }} direction="column" alignItems={"center"}>
          <img style={{ height: 120, width: 120 }} src={Logo} alt="Logo" />
        </Stack>
      </Stack>
      <Outlet />
    </Container>
  );
};

export default MainLayout;
