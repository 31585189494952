import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { GeneralSettingAxios } from "../services/axios";
import isEmpty from "lodash/isEmpty";
import { getCookie, CookiesKeys, setCookie } from "../utils/cookie";
import { getErrorMessage } from "../utils/error";
import LoadingWrapper from "../components/LoadingWrapper";

interface OptionSetting {
  id: string;
  created_at: string;
  updated_at: string;
  entity_term_taxonomy_id: string;
  group_key: string;
  order: 0;
  key: string;
  name: string;
  value: string;
  json_value: JSON;
  autoload: true;
  description: string;
  option_meta_list: [];
}

export enum SETTINGS_KEYS {
  user_register_enable = "user_register_enable",
}

const SettingContext = createContext<{
  settings: Record<string, any> | undefined;
}>({
  settings: undefined,
});

export const SettingContextProvider = ({ children }: PropsWithChildren) => {
  const [settings, setSettings] = useState<Record<string, any> | undefined>(
    undefined
  );

  const getConfigSettings = async () => {
    try {
      const cookieSetting = getCookie(CookiesKeys.user_register_enable);
      const cookieAuthData = getCookie(CookiesKeys.auth_data);
      if (cookieSetting === undefined || isEmpty(cookieAuthData)) {
        const resultCanRegister = await GeneralSettingAxios.getReq<{
          data: OptionSetting;
        }>(`/option/get-one-by-key/${SETTINGS_KEYS.user_register_enable}`);
        const newSettings = {
          canRegister: resultCanRegister.data.value === "true",
        };
        setSettings(newSettings);

        setCookie(CookiesKeys.all_settings, newSettings);
      } else {
        setSettings(cookieSetting);
      }
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  useEffect(() => {
    getConfigSettings();
  }, []);

  return (
    <SettingContext.Provider
      value={{
        settings,
      }}
    >
      <LoadingWrapper isLoading={!settings}>{children}</LoadingWrapper>
    </SettingContext.Provider>
  );
};

export const useSettingContext = () => {
  return useContext(SettingContext);
};
