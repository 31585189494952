export enum LocalStorageKey {
  "KeyPair" = "KeyPair",
  "RoomEncryptKey" = "RoomEncryptKey",
  "RoomMember" = "RoomMember",
  "UserData" = "UserData",
  "PasswordIsSet" = "PasswordIsSet",
  "SideBarWidth" = "SideBarWidth",
  "TextInputMode" = "TextInputMode",
  "ChatUnreadCount" = "ChatUnreadCount",
}

export enum LocalStorageKeyPrefix {
  MESSAGE_CONTENT = "MESSAGE_CONTENT",
  ROOM_ENCRYPT = "ROOM_ENCRYPT",
}
