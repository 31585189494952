import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { SocketEventTypeEnum } from "../enums";
import {
  ChatAxios,
  ChatWSAxios,
  MessageMeta,
  MessageType,
  MessageTypeEnum,
  Room,
  UserMessage,
} from "../services";
import { mergeArrays } from "../utils/array";
import { getErrorMessage } from "../utils/error";
import { useChatContext } from "./ChatContext";
import { useAuthContext } from "./AuthContext";
import { v4 as uuidv4 } from "uuid";
import { string } from "prop-types";
import { AppMessage, AppMessageSubType } from "../types";
import { MESSAGE_TYPE, SUBTYPE } from "../constant/room";

interface ChatMessageContent {
  content: string;
  created_at: string;
  creator_id: string;
  deleted_at: string;
  id: string;
  message_meta: string;
  message_type: string;
  updated_at: string;
  updater_id: string;
}

interface NotificationInfo {
  senderName: string;
  roomName: string;
}

const MessageContext = createContext<{
  messageListByRoom: Record<string, UserMessage[]>;
  setMessageListByRoom: (value: Record<string, UserMessage[]>) => void;
  selectedThread: UserMessage | undefined;
  setSelectedThread: (value: UserMessage) => void;
  loadingMessages: Record<string, boolean>;
  setLoadingMessages: (value: Record<string, boolean>) => void;
  totalMessagesByRoom: Record<string, number>;
  setTotalMessagesByRoom: (value: Record<string, number>) => void;
  getRoomMessagesFromLocalStorage: (roomId: string) => void;
  getRoomMessagesFromServer: (
    roomId: string,
    params: { limit: number; offset: number }
  ) => void;
  handleCreateNewMessage: (
    data: {
      messageType: MessageType;
      messageContent?: string;
      messageMeta?: string;
      threadId?: string;
      roomId?: string;
      notificationInfo: NotificationInfo;
      additionalData: {
        messageId: string;
        content: string;
      };
    },
    socketRef: any
  ) => Promise<void>;
  dataSendingMessage: UserMessage[];
  setDataSendingMessage: React.Dispatch<React.SetStateAction<UserMessage[]>>;
  handleAddSendingMessage: (message: UserMessage) => void;
  messageFailed: UserMessage[];
  setMessageFailed: React.Dispatch<React.SetStateAction<UserMessage[]>>;
  handleRemoveSendingMessage: (idMessate: string) => void;
}>({
  messageListByRoom: {},
  setMessageListByRoom: () => {},
  selectedThread: undefined,
  setSelectedThread: () => {},
  loadingMessages: {},
  setLoadingMessages: () => {},
  totalMessagesByRoom: {},
  setTotalMessagesByRoom: () => {},
  getRoomMessagesFromLocalStorage: () => {},
  getRoomMessagesFromServer: () => {},
  handleCreateNewMessage: () => Promise.resolve(),
  dataSendingMessage: [],
  setDataSendingMessage: () => {},
  handleAddSendingMessage: () => {},
  messageFailed: [],
  setMessageFailed: () => {},
  handleRemoveSendingMessage: () => {},
});

export const MessageContextProvider = ({ children }: PropsWithChildren) => {
  const currentDate = new Date();

  const [messageListByRoom, setMessageListByRoom] = useState<
    Record<string, UserMessage[]>
  >({});
  const [totalMessagesByRoom, setTotalMessagesByRoom] = useState<
    Record<string, number>
  >({});
  const [selectedThread, setSelectedThread] = useState<UserMessage>();
  const [loadingMessages, setLoadingMessages] = useState({});
  const [dataSendingMessage, setDataSendingMessage] = useState<UserMessage[]>(
    []
  );
  const [messageFailed, setMessageFailed] = useState<UserMessage[]>([]);
  const { userData } = useAuthContext();

  const { selectedRoom, rooms, setRooms } = useChatContext();
  // const { channel } = useChannelContext();
  // const { roomEncryptKeys } = useKeysStore();
  const roomsRef = useRef<Room[]>();
  const messageListByRoomRef = useRef<typeof messageListByRoom>();
  // const roomEncryptKeysRef = useRef<Record<string, string>>({});
  const loadingMessageRef = useRef<typeof loadingMessages>();

  const selectedRoomRef = useRef<Room>();
  const selectedThreadRef = useRef<typeof selectedThread>();
  const roomListRef = useRef<Room[]>([]);

  useEffect(() => {
    selectedRoomRef.current = selectedRoom;
  }, [selectedRoom]);

  useEffect(() => {
    if (selectedRoom?.id !== selectedRoomRef.current?.id) {
      setSelectedThread(undefined);
    }
  }, [selectedRoom]);

  useEffect(() => {
    messageListByRoomRef.current = messageListByRoom;
  }, [messageListByRoom]);

  useEffect(() => {
    roomsRef.current = rooms;
  }, [rooms]);

  useEffect(() => {
    loadingMessageRef.current = loadingMessages;
  }, [loadingMessages]);

  useEffect(() => {
    selectedThreadRef.current = selectedThread;
  }, [selectedThread]);

  // const channelRef = useRef<typeof channel>({});

  // useEffect(() => {
  //   channelRef.current = channel;
  // }, [channel]);

  // useEffect(() => {
  //   roomEncryptKeysRef.current = roomEncryptKeys;
  // }, [roomEncryptKeys]);

  // const { checkIsRoomP2PConnected } = useRoomHelper();
  // const { handleJoinWebRTCRoom } = useRTCContext();

  // const { roomKeyExchangeStatus } = useKeyExchange();
  // const { canSendMessage } = useCreateMessageContext();

  const getRoomMessagesFromLocalStorage = useCallback((roomId: string) => {
    let messageListByRoom = [];

    try {
      messageListByRoom = JSON.parse(localStorage.getItem(roomId) ?? "[]");
      setMessageListByRoom({
        ...messageListByRoomRef.current,
        [roomId]: messageListByRoom,
      });
    } catch (error) {
      console.log("Cannot get room messageListByRoom");
    }
  }, []);

  const getRoomMessagesFromServer = useCallback(
    async (roomId: string, params: { limit: number; offset: number }) => {
      try {
        setLoadingMessages({
          ...loadingMessageRef.current,
          [roomId]: true,
        });
        const messages = await ChatWSAxios.getReq("/api/messages/get-list", {
          params: {
            roomId: roomId,
            ...params,
          },
        });
        await processMessageList(roomId, {
          ...messages,
          offset: params.offset,
        });
        setTotalMessagesByRoom({
          ...totalMessagesByRoom,
          [roomId]: messages.total,
        });
      } catch (error) {
        toast.error("Error get room messages");
      } finally {
        setLoadingMessages({
          ...loadingMessageRef.current,
          [roomId]: false,
        });
      }
    },
    []
  );

  const processMessageList = useCallback(
    async (
      roomId: string,
      data: {
        messages: UserMessage[];
        total: number;
        limit: number;
        offset: number;
      }
    ) => {
      let messageContents: Record<
        string,
        { content: string; messageMeta: string }
      > = {};
      let messagesToGetContent = data.messages
        .filter((item) => !item.message.content && !item.message.messageMeta)
        .map((item) => item.messageId);
      messagesToGetContent = messagesToGetContent.concat(
        data.messages.reduce((pre, curr) => {
          return (pre ?? []).concat(
            (curr.threadMessages ?? [])
              ?.filter(
                (item) => !item.message.content && !item.message.messageMeta
              )
              .map((item) => item.messageId) ?? []
          );
        }, [] as string[])
      );
      try {
        const messages = await ChatAxios.postReq<{
          data: ChatMessageContent[];
        }>("/chat/get-list-message", {
          id_list: messagesToGetContent,
        });
        messageContents = messages.data.reduce(
          (pre, curr) => ({
            ...pre,
            [curr.id]: {
              content: curr.content,
              messageMeta: curr.message_meta,
            },
          }),
          {}
        );
      } catch (error) {
        console.log("cannot get message content");
      }

      setMessageListByRoom({
        ...messageListByRoomRef.current,
        [roomId]: mergeArrays(
          "id",
          (messageListByRoomRef.current[roomId] ?? []).slice(0, data.offset),
          data.messages.map((message) => {
            if (!messageContents[message.messageId]) {
              return message;
            }

            return {
              ...message,
              message: {
                ...message.message,
                content:
                  messageContents[message.messageId].content ??
                  message.message.content,
                messageMeta:
                  messageContents[message.messageId].messageMeta ??
                  message.message.messageMeta,
              },
              threadMessages:
                message.threadMessages?.map((thread) => {
                  const currentMessage = messageContents[thread.messageId];
                  if (!currentMessage) {
                    return thread;
                  }
                  return {
                    ...thread,
                    message: {
                      ...message.additionalData,
                      ...thread.message,
                      content: currentMessage.content ?? thread.message.content,
                      messageMeta:
                        currentMessage.messageMeta ??
                        thread.message.messageMeta,
                    },
                  };
                }) ?? [],
            };
          })
        ),
      });
    },
    []
  );

  const handleAddSendingMessage = useCallback((message: UserMessage) => {
    setDataSendingMessage((prevMessages) => [...prevMessages, message]);
  }, []);

  const handleRemoveSendingMessage = useCallback((messageId: string) => {
    setDataSendingMessage((prevMessages) => {
      const updatedMessages = prevMessages.filter(
        (item) => item?.additionalData?.messageId !== messageId
      );
      return updatedMessages;
    });
  }, []);

  const sendMessage = async (
    data: {
      content?: string;
      messageType: MessageType;
      threadId?: string;
      messageMeta?: any;
      roomId: string;
      notificationInfo: NotificationInfo;
      additionalData: {
        messageId: string;
        content: string;
      };
    },
    socketRef
  ) => {
    const {
      content,
      messageMeta,
      messageType,
      threadId,
      roomId,
      notificationInfo,
      additionalData,
    } = data;
    if (socketRef && socketRef.current) {
      socketRef.current.emit(SocketEventTypeEnum.addMessage, {
        content,
        threadId,
        messageMeta,
        messageType,
        roomId,
        notificationInfo,
        additionalData,
      });
    }
  };

  const handleCreateNewMessage = useCallback(
    async (
      data: {
        messageType: MessageType;
        messageContent?: string;
        messageMeta?: string;
        threadId?: string;
        roomId?: string;
        notificationInfo: NotificationInfo;
        additionalData: {
          messageId: string;
          content: string;
        };
      },
      socketRef
    ) => {
      let {
        messageType,
        messageContent,
        messageMeta,
        threadId,
        roomId,
        notificationInfo,
      } = data;
      try {
        if (!messageContent && !messageMeta) {
          toast.error("Message content is empty");
        }
        const messageId = uuidv4();

        let meta: MessageMeta | null;
        if (messageMeta) {
          meta = JSON.parse(messageMeta);
        } else {
          meta = null;
        }

        let subtype: AppMessageSubType;
        if (messageType === MESSAGE_TYPE.FILE && meta) {
          if (meta.file_type.includes("image")) {
            subtype = SUBTYPE.IMG;
          } else {
            subtype = SUBTYPE.DOC;
          }
        } else {
          subtype = SUBTYPE.TEXT;
        }

        const data = {
          messageType,
          threadId,
          roomId,
          content: undefined,
          messageMeta: undefined,
          notificationInfo,
          additionalData: {
            messageId,
            content: messageContent,
          },
        };

        const newMessage = {
          createdAt: currentDate.toISOString(),
          senderId: userData?.id,
          receiverId: null,
          roomId: roomId,
          threadId,
          notificationInfo,
          message: {
            subtype,
            id: messageId,
            messageMeta: null,
            content: messageContent,
            meta,
            messageType,
            img: meta?.plain_url,
          },
          threadMessages: [],
          additionalData: {
            messageId,
            content: messageContent,
            isSending: true,
          },
        };

        switch (messageType) {
          case MessageTypeEnum.text:
            data.content = messageContent;
            break;
          case MessageTypeEnum.file:
            data.messageMeta = messageMeta;
            break;
        }
        handleAddSendingMessage(newMessage);
        sendMessage(data, socketRef);
      } catch (error) {
        toast.error(getErrorMessage(error));
      }
    },
    []
  );

  return (
    <MessageContext.Provider
      value={{
        messageListByRoom,
        setMessageListByRoom,
        selectedThread,
        setSelectedThread,
        loadingMessages,
        setLoadingMessages,
        totalMessagesByRoom,
        setTotalMessagesByRoom,
        getRoomMessagesFromLocalStorage,
        getRoomMessagesFromServer,
        handleCreateNewMessage,
        dataSendingMessage,
        setDataSendingMessage,
        handleRemoveSendingMessage,
        messageFailed,
        setMessageFailed,
        handleAddSendingMessage,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => {
  return useContext(MessageContext);
};
