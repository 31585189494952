import { LocalStorageKey } from "../constant/local-storage";
import { TextInputModeType } from "../types/text-input";

export const getRoomUsers = (roomId: string) => {
  const roomUsers = JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomMember) ?? "{}"
  );
  return roomUsers[roomId] ?? [];
};

export const getUserKeyPair = () => {
  const keyPair = JSON.parse(
    localStorage.getItem(LocalStorageKey.KeyPair) ?? "{}"
  );
  return keyPair as { public_key: string; private_key: string };
};

export const setSideBarWidth = (width: number) => {
  localStorage.setItem(LocalStorageKey.SideBarWidth, width.toString());
};

export const getSidebarWidth = () => {
  try {
    return JSON.parse(localStorage.getItem(LocalStorageKey.SideBarWidth) ?? "");
  } catch (_) {
    return null;
  }
};

export const getTextInputMode = (): TextInputModeType => {
  return (
    (localStorage.getItem(
      LocalStorageKey.TextInputMode
    ) as TextInputModeType) ?? "plain"
  );
};

export const setTextInputMode = (mode: TextInputModeType) => {
  localStorage.setItem(LocalStorageKey.TextInputMode, mode);
};

export const setRoomEncryptKey = (
  roomId: string,
  exchangeEncryptKey: string
) => {
  const previousKeys = JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
  localStorage.setItem(
    LocalStorageKey.RoomEncryptKey,
    JSON.stringify({
      ...previousKeys,
      [roomId]: exchangeEncryptKey,
    })
  );
};
export const getRoomExchangeEncryptKey = (roomId: string) => {
  const keys = JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
  return keys[roomId] ?? "";
};

export const getAllRoomExchangeEncryptKeys = () => {
  return JSON.parse(
    localStorage.getItem(LocalStorageKey.RoomEncryptKey) ?? "{}"
  );
};

export const setLocalChatUnreadCount = (roomId: string, count: number) => {
  const previousCounts = JSON.parse(
    localStorage.getItem(LocalStorageKey.ChatUnreadCount) ?? "{}"
  );

  localStorage.setItem(
    LocalStorageKey.ChatUnreadCount,
    JSON.stringify({
      ...previousCounts,
      [roomId]: count,
    })
  );
};

export const getAllLocalChatUnreadCount = () => {
  return JSON.parse(
    localStorage.getItem(LocalStorageKey.ChatUnreadCount) ?? "{}"
  );
};
