export type RoomMessageType = "normal" | "non_many";
export type RoomCreateStateType = "creating" | "done" | "adding";
export enum MessageTypeEnum {
  file = "file",
  html = "html",
  text = "text",
}
export type MessageType = keyof typeof MessageTypeEnum;

export type UserRoomStatusType =
  | "joined"
  | "rejected"
  | "banned"
  | "removed"
  | "left"
  | "requested";

export type RoomDisplayType = "public" | "normal" | "private";
export type RoomConnectionType = "server" | "p2p";
export type RoomEncryptType = "none" | "aes_256_cbc" | "key_exchange";
export type UserRoomInitState = "init" | "done";
export type UserRoomCallStatusType = "connected" | "disconnected";
export type RoomKeyExchangeStatus =
  | "created"
  | "ongoing"
  | "isOwnerDone"
  | "done";

export type UserRoomStatusKeyExchangeStatus =
  | "created"
  | "done"
  | "requestRejoin";

export interface RoomCall {
  createdAt: string;
  endAt: string;
  id: string;
  isActive: boolean;
  name: string;
  roomId: string;
  updatedAt: string;
  Room: Room;
  ownerUserRoomId: string;
  OwnerUserRoom: UserRoom;
}

export interface UserRoomCallStatus {
  id: string;
  createdAt: string;
  updatedAt: string;
  userRoomId: string;
  UserRoom: UserRoom;
  status: UserRoomCallStatusType;
  roomCallId: string;
  RoomCall: RoomCall;
}

export interface UserRoomStatus {
  allowGetPreviousMessage: boolean;
  createdAt: string;
  id: string;
  status: UserRoomStatusType;
  userRoomId: string;
  UserRoom: UserRoom;
  publicKey: string;
}

export interface ChatUserInfo {
  id: string;
  email: string;
  username: string;
  avatar: string;
}

export interface UserRoom {
  userId: string;
  joinedAt: string;
  nickname: string | null;
  isCreator: boolean;
  User: ChatUserInfo;
  Room: Room[];
  initState: UserRoomInitState;
  UserRoomStatus: UserRoomStatus[];
  id: string;
  roomId: string;
}

export interface UserRoomStatusKeyExchange {
  id: string;
  createdAt: string;
  updatedAt: string;
  isActive: string;
  userRoomStatusId: string;
  publicKey: string;
  status: UserRoomStatusKeyExchangeStatus;
  roomKeyExchangeId: string;
  UserRoomStatus: UserRoomStatus;
}

export interface RoomKeyExchange {
  createdAt: string;
  id: string;
  isActive: boolean;
  members: Record<string, string>;
  roomId: string;
  status: RoomKeyExchangeStatus;
  updatedAt: string;
  UserRoomStatusKeyExchange: UserRoomStatusKeyExchange[];
}

export interface Room {
  id: string;
  key: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  messageType: RoomMessageType;
  connectionType: RoomConnectionType;
  encryptType: RoomEncryptType;
  displayType: RoomDisplayType;
  users: UserRoom[];
  status: UserRoomStatusType | null;
  createState?: RoomCreateStateType;
  RoomCall: RoomCall[];
  RoomKeyExchange: RoomKeyExchange[];
  keyExchangeStatus: RoomKeyExchangeStatus;
  currentUserRoomStatus: UserRoomStatus;
  avatar?: string;
  latestMessage: LatestMessage | null;
  description: string;
}

export interface LatestMessage extends Message {
  senderUser: UserRoom;
}

export interface Message {
  id: string;
  content: string | null;
  createdAt: string;
  updatedAt: string;
  messageMeta: string | null;
  messageType: MessageType;
  isSending?: boolean
}

export interface MessageMeta {
  alt_text: string;
  author_id: string;
  author_type: string;
  caption: string;
  created_at: string;
  creator_id: string;
  description: string;
  file_type: string;
  id: string;
  key: string;
  name: string;
  path: string;
  plain_url: string;
  short_link: string;
  size: number;
  slug: string;
  sub_title: string;
  title: string;
  updated_at: string;
}

export interface UserMessage {
  messageId: string;
  senderId: string;
  receiverId?: string | null;
  roomId: string;
  threadId?: string | null;
  message: Message;
  threadMessages: UserMessage[];
  id: string;
  createdAt: string;
  additionalData?: {
    messageId: string,
    content: string,
    isSending: boolean,
  }
  isSending: boolean;
}
