import { createSlice } from "@reduxjs/toolkit";
import { SidebarTypeEnum } from "../../types";

// define initial state
const initialState = {
  sidebar: {
    open: true,
    type: SidebarTypeEnum.CONTACT,
  },
};

// create slice
const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    //Toggle sidebar
    toggleSidebar(state, action) {
      state.sidebar.open = action.payload;
    },
    updateSidebarType(state, action) {
      state.sidebar.type = action.payload.type;
    },
  },
});

// export reducer
export default slice.reducer;

// export actions
export const { toggleSidebar, updateSidebarType } = slice.actions;
