import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { toast } from "react-toastify";

import {
  CookiesKeys,
  getCookie,
  removeCookie,
  setCookie,
} from "../utils/cookie";
import { CustomHeader } from "../enums";
import { config } from "../config";

const FORCE_RELOGIN_ERROR = [
  "invalid token: record not found",
  "please login again",
];

export const getRequestHeaders = (additionalHeaders?: any) => {
  const user = getCookie(CookiesKeys.auth_data);
  const headers: Record<string, string> = {};

  if (user?.token) {
    headers["Authorization"] = "Bearer " + user.token;
    if (config.ENV === "local") {
      headers[CustomHeader.UserId] =
        user.user_info?.id ?? user.external_user_info?.index;
      headers[CustomHeader.EntityTermKey] = "jChat";
      headers[CustomHeader.EntityTaxonomyId] =
        "e06db809-53e5-444f-a44f-b57bfe56ad67";
    }
  }

  return { ...headers, ...(additionalHeaders ?? {}) };
};

class CRUDAxios {
  axiosInstance: AxiosInstance;

  constructor(baseURL: string) {
    this.axiosInstance = axios.create({
      baseURL: baseURL,
      timeout: 30000,
      headers: {
        "x-access-token": config.ACCESS_TOKEN,
      },
    });

    this.axiosInstance.interceptors.response.use(
      undefined,
      async (errorResponse: AxiosError) => {
        if (errorResponse?.response?.status !== 401)
          return Promise.reject(errorResponse);

        if (baseURL === config.API_BASE_URL)
          return Promise.reject(errorResponse);

        if (baseURL === config.API_BASE_URL_CUSTOMER) {
          if (
            FORCE_RELOGIN_ERROR.some((error) =>
              ((errorResponse.response?.data as any) ?? {})?.error?.includes(
                error
              )
            )
          ) {
            if (getCookie(CookiesKeys.auth_data)) {
              localStorage.clear();
              removeCookie(CookiesKeys.auth_data);
              removeCookie(CookiesKeys.login_type);
              removeCookie(CookiesKeys.user_register_enable);
              localStorage.clear();
              window.location.href = "/auth/login";
            }
          }
        }

        const cookieLoginType = getCookie(CookiesKeys.login_type);
        if (cookieLoginType !== "normal") {
          removeCookie(CookiesKeys.auth_data);
          localStorage.clear();
          toast.error("Please login again");
          window.location.href = "/auth/login";
          return Promise.reject(errorResponse);
        }

        const originalRequest = errorResponse.config;

        const cookieUser = getCookie(CookiesKeys.auth_data);

        try {
          const result = await this.axiosInstance("/user/auth/refresh", {
            headers: {
              "x-refresh-token": cookieUser.refresh_token,
              Authorization: "",
            },
          });
          const { token: newToken } = result.data?.data ?? {};
          setCookie(CookiesKeys.auth_data, {
            ...cookieUser,
            token: newToken,
          });
          const recallResult = this.axiosInstance.request({
            ...originalRequest,
            headers: getRequestHeaders({
              ...originalRequest?.headers,
              Authorization: `Bearer ${newToken}`,
            }),
          });
          return recallResult;
        } catch (error) {
          removeCookie(CookiesKeys.user_register_enable);
          removeCookie(CookiesKeys.auth_data);
          removeCookie(CookiesKeys.login_type);
          localStorage.clear();
          window.location.href = "/auth/login";
          return Promise.reject(error);
        }
      }
    );

    this.axiosInstance.interceptors.request.use((request) => {
      return request;
    });

    this.axiosInstance.interceptors.response.use((response) => {
      return response;
    });
  }

  async getReq<T = any>(path: string, option?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.get(path, {
      ...(option ?? {}),
      headers: getRequestHeaders(option?.headers ?? {}),
    });
    return response?.data;
  }

  async postReq<T = any>(
    path: string,
    data: any = {},
    option?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.post(
      path,
      data,
      {
        ...(option ?? {}),
        headers: getRequestHeaders(option?.headers ?? {}),
      }
    );
    return response.data;
  }

  async putReq<T = any>(
    path: string,
    data?: any,
    option?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.put(
      path,
      data,
      {
        ...(option ?? {}),
        headers: getRequestHeaders(option?.headers ?? {}),
      }
    );

    return response.data;
  }

  async patchReq<T = any>(
    path: string,
    data?: any,
    option?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.patch(
      path,
      data,
      {
        ...(option ?? {}),
        headers: getRequestHeaders(option?.headers ?? {}),
      }
    );

    return response.data;
  }

  async deleteReq<T = any>(
    path: string,
    data?: any,
    option?: AxiosRequestConfig
  ): Promise<T> {
    const response: AxiosResponse<T> = await this.axiosInstance.delete(path, {
      ...(option ?? {}),
      headers: getRequestHeaders(option?.headers ?? {}),
      data,
    });
    return response.data;
  }
}

export const CustomerAxios = new CRUDAxios(config.API_BASE_URL_CUSTOMER);
export const ChatWSAxios = new CRUDAxios(config.API_BASE_URL);
export const ChatAxios = new CRUDAxios(config.API_BASE_URL_CHAT);
export const GeneralSettingAxios = new CRUDAxios(
  config.API_BASE_URL_GENERAL_SETTING
);
export const MediaAxios = new CRUDAxios(config.API_BASE_URL_MEDIA);

export const TermAxios = new CRUDAxios(config.API_BASE_URL_TERM);
export const NotificationAxios = new CRUDAxios(
  config.API_BASE_URL_NOTIFICATION
);
