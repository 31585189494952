import { UserProfileInfo } from "../components/create-room-dialog/types";
import { ChatUserInfo, UserInfo } from "../contexts";
import { Room, UserRoom, UserRoomStatus } from "../services";

const ChatUserHelper = {
  getUserDisplayString: (user: ChatUserInfo| UserProfileInfo) => {
    return (
      (user as ChatUserInfo)?.username ??
      (user as ChatUserInfo)?.email ??
      (user as ChatUserInfo)?.id ??
      (user as UserProfileInfo)?.phone_number ??
      ""
    )
      .split("|")
      .pop();
  },
};

export const getAvatar = (data: Room | UserInfo| UserRoomStatus| UserRoom) => {
  return (
    (data as Room)?.avatar ||
    (data as UserInfo)?.user_profile_info?.avatar ||
    (data as UserRoomStatus)?.UserRoom?.User?.avatar ||
    (data as UserRoom)?.User?.avatar ||
    "/assets/Images/default_image.png"
  );
};

export const formatMessageWithLinks = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/[^\s]*)?/gi;

  return message.replace(urlRegex, (url) => {
      const href = url.startsWith('http') ? url : `https://${url}`;
      return `<a href="${href}" target="_blank">${url}</a>`;
  });
}

export const isLink = (text) => {
  const urlRegex =
    /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(\/[^\s]*)?/gi;
  return urlRegex.test(text);
}

export default ChatUserHelper;
