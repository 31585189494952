import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import { getErrorMessage } from "../utils/error";

interface ConfirmDialogData extends DialogProps {
  title: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => any;
  onCancel?: () => void;
  hideCancel?: boolean;
}

interface ConfirmDialogContextType {
  dialog: ConfirmDialogData;
  openConfirmDialog: (data: Omit<ConfirmDialogData, "open">) => void;
  clearConfirmDialog: () => void;
}

interface ConfirmDialogProviderProps {
  children: ReactNode;
}

const ConfirmDialogContext = createContext<
  ConfirmDialogContextType | undefined
>(undefined);

export function useConfirmDialog() {
  const context = useContext(ConfirmDialogContext);
  if (!context) {
    throw new Error(
      "useConfirmDialog must be used within a ConfirmDialogProvider"
    );
  }
  return context;
}

export default function ConfirmDialogProvider({
  children,
}: ConfirmDialogProviderProps) {
  const INITIAL_CONFIRM_DIALOG_DATA: ConfirmDialogData = useMemo(
    () => ({
      open: false,
      title: "",
      content: "",
      hiddentCancel: "",
    }),
    []
  );
  const [dialog, setDialog] = useState(INITIAL_CONFIRM_DIALOG_DATA);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const openConfirmDialog = useCallback(
    (data: Omit<ConfirmDialogData, "open">) => {
      setDialog({ ...data, open: true });
    },
    []
  );

  const clearConfirmDialog = useCallback(() => {
    setDialog(INITIAL_CONFIRM_DIALOG_DATA);
  }, [dialog]);

  const handleConfirm = useCallback(async () => {
    try {
      setLoadingConfirm(true);
      await dialog.onConfirm?.();
      setDialog(INITIAL_CONFIRM_DIALOG_DATA);
      setLoadingConfirm(false);
    } catch (error) {
      setLoadingConfirm(false);
      toast.error(getErrorMessage(error));
    }
  }, [dialog]);

  return (
    <ConfirmDialogContext.Provider
      value={{ dialog, openConfirmDialog, clearConfirmDialog }}
    >
      {children}

      {dialog.open && (
        <Dialog
          open={dialog.open}
          onClose={clearConfirmDialog}
          PaperProps={{
            sx: { p: 2 },
          }}
        >
          <DialogTitle sx={{ textAlign: "center" }}>{dialog.title}</DialogTitle>
          {dialog.content && (
            <DialogContent>
              <DialogContentText sx={{ textAlign: "center" }}>
                {dialog.content}
              </DialogContentText>
            </DialogContent>
          )}
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "center",
              padding: "8px 24px",
            }}
          >
            {!dialog.hideCancel && (
              <Button
                variant="contained"
                color="inherit"
                fullWidth
                onClick={clearConfirmDialog}
              >
                {dialog.cancelText || "cancel"}
              </Button>
            )}
            <Button
              //   onClick={handleConfirm}
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleConfirm}
              disabled={loadingConfirm}
            >
              {loadingConfirm ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                dialog.confirmText || "Confirm"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </ConfirmDialogContext.Provider>
  );
}
